import { Grid } from "@material-ui/core"
import { FormSectionHeading } from "../../../../components/shared/Registration/FormSectionHeading/FormSectionHeading"
import React from "react"
import styled from "styled-components"
import { StreetField } from "./fields/StreetField"
import { CityField } from "./fields/CityField"
import { ZipField } from "./fields/ZipField"

export interface AddressSectionProps {
  className?: string
}

const AddressSectionInner = (props: AddressSectionProps) => {
  return (
    <section className={props.className}>
      <FormSectionHeading>Adresa trvalého pobytu</FormSectionHeading>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <StreetField />
        </Grid>
        <Grid item md={6} xs={12}>
          <CityField />
        </Grid>
        <Grid item md={6} xs={12}>
          <ZipField />
        </Grid>
      </Grid>
    </section>
  )
}

export const AddressSection = styled(AddressSectionInner)`
  .inpt-zip {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
