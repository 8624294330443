import React from "react"
import styled from "styled-components"

interface Props {
  title: string
  description: string
  position: "left" | "right"
}

const CustomContainer = styled.div<{ after: any }>`
  padding: 0px 40px;
  position: relative;
  background-color: white;
  width: 50%;

  &:last-child {
    top: 25px;

    &::after {
      background-color: white;
      background-image: url("/driving.svg");
      border: none;
      width: 100px;
      height: 100px;
      left: -45px !important;
      @media screen and (max-width: 600px) {
        left: -14px !important;
        bottom: 0;
        top: auto;
      }
    }
  }

  &::after {
    content: ${({ after }) => '"' + after + '"'};
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-weight: 600;

    position: absolute;
    width: 50px;
    height: 50px;
    right: 50px;
    background-color: #a0c1ed;
    border: 4px solid #a0c1ed;
    top: 30%;
    border-radius: 50%;
    z-index: 1;
  }

  /* Place the container to the left */
  &.left {
    left: 0;
    padding-right: 0px;

    &::before {
      content: " ";
      height: 0;
      position: absolute;
      top: 22px;
      width: 0;
      z-index: 1;
      right: 30px;
      border: medium solid white;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent #e7ecf336;
    }

    &::after {
      right: -23px;
    }
  }

  /* Place the container to the right */
  &.right {
    left: 50%;

    &::before {
      content: " ";
      height: 0;
      position: absolute;
      top: 22px;
      width: 0;
      z-index: 1;
      left: 30px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent #e7ecf336 transparent transparent;
    }

    &::after {
      left: -28px;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;

    &::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }

    &.right::after {
      left: 3px;
    }

    &.left::after {
      left: 3px;
    }

    &.right {
      left: 0%;
    }
  }
`

const map = {
  left: "right",
  right: "left",
}

const Content = styled.div`
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  max-width: 500px;
  text-align: ${(props: { position: string }) => map[props.position]};
  padding-right: ${(props: { position: string }) => (props.position === "left" ? "50px" : "30px")};
  @media screen and (max-width: 600px) {
    text-align: left;
    padding-left: 15px;
  }
`

const Title = styled.h2`
  font-size: 28px;
  &.left {
    margin-right: 50px;
  }
  @media screen and (max-width: 600px) {
    margin: 0;
    margin-right: 10px;
    font-size: 20px;
  }
`

const Description = styled.p`
  margin-right: ${(props: { position: string }) => (props.position === "left" ? "50px" : "30px")};
`

const TimeBox: React.FC<Props> = (props) => {
  const number = props.title.split(".")

  return (
    <CustomContainer className={props.position} position={props.position} after={""}>
      <Content
        position={props.position}
        data-sal-duration="650"
        data-sal="slide-up"
        data-sal-easing="ease-in"
        data-sal-delay="200"
      >
        <Title className={props.position}>{props.title}</Title>
        <Description position={props.position}>{props.description}</Description>
      </Content>
    </CustomContainer>
  )
}
export default TimeBox
