import { guardianSectionValidation } from "./../sections/GuardianSection/GuardianSection"
import { studentContactSectionValidations } from "./../sections/StudentContactSection/StudentContactSection"
import * as Yup from "yup"
import { addressSectionValidations } from "../sections/AddressSection/utils/validations"
import { studentSectionValidations } from "../sections/StudentInfo/utils/validations"
import { loginSectionValidations } from "../sections/LoginSection/LoginSection"
import { guardianSectionInitialValues } from "../sections/GuardianSection/GuardianSection"
import { agreementSectionValidations } from "../sections/AgreementSection/AgreementSection"

export const registrationFormValidationSchema = Yup.object().shape({
  ...studentSectionValidations,
  ...addressSectionValidations,
  ...loginSectionValidations,
  ...studentContactSectionValidations,
  ...guardianSectionValidation,
  ...agreementSectionValidations,
})
// firstName: Yup.string().required('Políčko "Meno" je povinné.'),
// lastName: Yup.string().required('Políčko "Priezvisko" je povinné.'),
// email: Yup.string()
//   .email('Políčko "Email" musí byť validné (obsahovať @).')
//   .required('Políčko "Email" je povinné.'),
// phone: Yup.string()
//   .matches(phoneRegExp, 'Políčko "Telefón" nie je validné.')
//   .required(
//     'Políčko "Telefón" je povinné, aby sme Ťa mohli kontaktovať.'
//   ),
// city: Yup.string().required('Políčko "Mesto" je povinné.'),
// street: Yup.string().required('Políčko "Ulica" je povinné.'),
// zipCode: Yup.string().required('Políčko "PSČ" je povinné.'),
// course: Yup.string().required('Políčko "Kurz" je povinné.'),
// birthDate: Yup.date().required(),
// agreement: Yup.bool().oneOf(
//   [true],
//   "Accept Terms & Conditions is required"
// ),
