import { CircularProgress, Grid } from "@material-ui/core"
import { Button } from "@material-ui/core/"
import dot from "dot-object"
import { Form, Formik, FormikHelpers as FormikActions } from "formik"
import { encode } from "js-base64"
import React from "react"
import { useMutation } from "react-query"
import styled from "styled-components"
import Swal from "sweetalert2"
import Card from "../shared/Card"
import api from "./../../lib/api/api"
import { FormHeader } from "./components/FormHeader"
import { AddressSection } from "./sections/AddressSection/AddressSection"
import { AgreementSection } from "./sections/AgreementSection/AgreementSection"
import { GuardianSection } from "./sections/GuardianSection/GuardianSection"
import { LoginSection } from "./sections/LoginSection/LoginSection"
import { StudentContactSection } from "./sections/StudentContactSection/StudentContactSection"
import { StudentInfo } from "./sections/StudentInfo/StudentInfo"
import { registrationFormInitialValues } from "./utils/initialValues"
import { registrationFormValidationSchema } from "./utils/validations"

const FormWrapp = styled.div`
  background-color: white;
`

const sections = [
  <StudentInfo />,
  <AddressSection />,
  <StudentContactSection />,
  <LoginSection />,
  <GuardianSection />,
  <AgreementSection />,
]

const RegistrationForm: React.FC = () => {
  const mutation = useMutation(async (values: any) => {
    const {
      data: { user },
    } = await api({
      method: "POST",
      url: `/auth/local/register`,
      data: {
        username: values.contact.email,
        email: values.contact.email,
        ...values,
      },
    })
    const { data } = await api({
      method: "POST",
      url: `/registrations`,
      data: {
        user: user.id,
      },
    })

    const encodedValues = encode(
      JSON.stringify({
        ...values,
        date: new Date(),
      })
    )

    api({
      method: "POST",
      url: `/registrations/email`,
      data: dot.dot({
        ...values,
        id: data.id,
        dokumenty: `https://autoskolahron.sk/dokumenty/download?d=${encodedValues}`,
      }),
    })

    api({
      method: "POST",
      url: `/registrations/documents-email`,
      data: {
        d: encodedValues,
        email: values.contact.email,
      },
    })
  })
  return (
    <div>
      <Card style={{ maxWidth: "950px", margin: " 0 auto" }}>
        <FormWrapp>
          <FormHeader />
          <Formik
            initialValues={registrationFormInitialValues}
            onSubmit={(values: any, { setStatus, resetForm }: FormikActions<any>) => {
              mutation
                .mutateAsync(values)
                .then(() => {
                  Swal.fire(
                    "Prihláška úspešne odoslaná!",
                    "Vaša prihláška bola úspešne odoslaná, po jej spracovaní Vás bude konktakovať na vami zadanom emaili !",
                    "success"
                  )

                  resetForm({})
                  setStatus({ success: true })
                })
                .catch(() => {
                  Swal.fire(
                    "Odoslanie neúspešné",
                    "Buď ste všetky údaje nezadali správne, alebo je daný email už registrovaný !",
                    "error"
                  )

                  setStatus({ success: false })
                })
            }}
            validationSchema={registrationFormValidationSchema}
          >
            <Form>
              <Grid container spacing={4}>
                {sections.map((section, index) => (
                  <Grid key={index} item md={12} xs={12}>
                    {section}
                  </Grid>
                ))}

                <Grid item md={12} xs={12}>
                  <Button
                    disabled={mutation.isLoading}
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="upper space-x-2 flex items-center"
                  >
                    <div>Odoslať</div>
                    <div className="ml-4 flex">
                      {mutation.isLoading && <CircularProgress size={30} color="secondary" />}
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </FormWrapp>
      </Card>
    </div>
  )
}

export default RegistrationForm
