import { FormSectionHeading } from "./../../../../components/shared/Registration/FormSectionHeading/FormSectionHeading"
import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { LastNameField } from "./fields/LastNameField"
import { FirstNameField } from "./fields/FirstNameField"
import { IdentificationNumberField } from "./fields/IdentificationNumberField"
import { BirthDateSelectField } from "./fields/BirthDateSelectField"
import { BirthPlaceField } from "./fields/BirthPlaceField"
import { IdNumberField } from "./fields/IdNumberField"

export interface StudentInfoProps {
  className?: string
}

const StudentInfoInner = (props: StudentInfoProps) => {
  return (
    <section className={props.className}>
      <FormSectionHeading>Osobné údaje uchádzača</FormSectionHeading>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <FirstNameField />
        </Grid>
        <Grid item md={6} xs={12}>
          <LastNameField />
        </Grid>
        <Grid item md={6} xs={12}>
          <IdentificationNumberField />
        </Grid>
        <Grid item md={6} xs={12}>
          <BirthDateSelectField />
        </Grid>
        <Grid item md={6} xs={12}>
          <BirthPlaceField />
        </Grid>
        <Grid item md={6} xs={12}>
          <IdNumberField />
        </Grid>
      </Grid>
    </section>
  )
}

export const StudentInfo = styled(StudentInfoInner)``
