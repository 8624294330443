import React from "react"

import TimeBox from "./components/TimeBox"
import styled from "styled-components"

const InnerTimeline = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  &::after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: #005acf;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    @media screen and (max-width: 600px) {
      left: 31px;
    }
  }
`

interface Props {}

const TimeLine: React.FC<Props> = (Props) => {
  return (
    <>
      <InnerTimeline>
        <TimeBox
          position="left"
          title="1. Prihláška"
          description="Vyplň prihlášku správnymi údajmi a odošli ju"
        ></TimeBox>
        <TimeBox
          position="right"
          title="2. Ukáž sa  "
          description="Príď na prvú hodinu teórie ."
        ></TimeBox>
        <TimeBox
          position="left"
          title="3. Pravidelné hodiny teórie"
          description="Snaž sa nevymeškať hodiny teórie."
        ></TimeBox>
        <TimeBox
          position="right"
          title="4. Prvá jazda"
          description="Konečne je to tu! Po všetkej tej teórií, prišiel čas na tvoju prvú jazdu. Tešíme sa s tebou."
        ></TimeBox>
        <TimeBox
          position="left"
          title="5. Červený kríž"
          description="Ak chceš vedieť jazdiť, musíš vedieť podať prvú pomoc!"
        ></TimeBox>
        <TimeBox
          position="right"
          title="6. Zvládnuť všetky jazdy"
          description="Aby si prešiel ku skúške, musíš mať odjazdené všetky potrebné hodiny."
        ></TimeBox>
        <TimeBox
          position="left"
          title="7. Nezaspi!"
          description="Nezmeškaj svoj termín skúšky a dostav sa naň včas!"
        ></TimeBox>
        <TimeBox
          position="right"
          title="8. Dôležitý papier!"
          description="Tým myslíme test.  Sprav test a ostanú ti len dve posledné úlohy do cieľa."
        ></TimeBox>
        <TimeBox
          position="left"
          title="9. Parkovať vieš?"
          description="Ukáž nám ako sme ťa naučili parkovať."
        ></TimeBox>
        <TimeBox
          position="right"
          title="10. Prevez nás po cestách"
          description="Posledný krok, tvojou úlohou je nás troška previezť po cestách, 
                    ale tadiaľ kade budeme my chcieť. 
                    Mať vodíčák je zodpovednosť, nie vždy budeš šoférovať len tam kam budeš chcieť ty ísť. :) "
        ></TimeBox>
        <TimeBox
          position="left"
          title="11. Konečná :)"
          description="Ak si úspešne zvládol a splnil všetko, čo sa od teba požadovalo, dostal si sa do cieľa."
        ></TimeBox>
        <TimeBox
          position="right"
          title="Gratulujeme"
          description="Vodický preukaz si môžeš ísť bežať vybaviť hneď na ďalší pracovný deň. :)"
        ></TimeBox>
      </InnerTimeline>
    </>
  )
}
export default TimeLine
