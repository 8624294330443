import { FastField } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"

export const BirthPlaceField = (props: any) => {
  return (
    <FastField
      style={{
        width: "100%",
      }}
      component={TextField}
      id="info.birthPlace"
      name="info.birthPlace"
      type="text"
      label="Miesto narodenia"
    ></FastField>
  )
}
