import { Grid } from "@material-ui/core"
import { FastField } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"
import styled from "styled-components"
import * as Yup from "yup"
import { FormSectionHeading } from "../../../../components/shared/Registration/FormSectionHeading/FormSectionHeading"

export interface StudentContactSectionProps {
  className?: string
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const studentContactSectionValidations = {
  contact: Yup.object({
    email: Yup.string().required('Pole "Email" je povinné.'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Pole "Telefón" nie je validné.')
      .required('Pole "Telefón" je povinné, aby sme Ťa mohli kontaktovať.'),
  }),
}

export const studentContactSectionInitialValues = {
  contact: {
    email: "",
    phone: "",
  },
}

const StudentContactSectionInner = (props: StudentContactSectionProps) => {
  return (
    <section className={props.className}>
      <FormSectionHeading>Kontaktné údaje uchádzača</FormSectionHeading>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            id="contact.email"
            name="contact.email"
            placeholder="jozko.mrkvicka@gmail.com"
            type="email"
            label="Email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            id="contact.phone"
            name="contact.phone"
            type="number"
            placeholder="+421 945 845 991"
            label="Telefón"
            className="inpt-phone"
          />
        </Grid>
      </Grid>
    </section>
  )
}

export const StudentContactSection = styled(StudentContactSectionInner)`
  .inpt-phone {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
