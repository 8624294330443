import { FastField } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"

export const StreetField = (props: any) => {
  return (
    <FastField
      style={{
        width: "100%",
      }}
      component={TextField}
      id="address.street"
      name="address.street"
      type="text"
      label="Ulica a číslo domu"
    />
  )
}
