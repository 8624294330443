import React from "react"
import Layout from "../components/shared/Layout/layout"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../components/shared/Layout/components/Section/Section"
import RegistrationForm from "../components/Registration/RegistrationForm"
import Timeline from "../components/Registration/Timeline"
import SEO from "../components/seo"
import { PageHeader } from "../components/shared/PageHeader/PageHeader"
const FormSection = styled(Section)`
  position: relative;
`
const TimelineSection = styled(Section)``
const Intro = styled.div`
  margin-bottom: 50px;
`
const BackSquare = styled.div`
  height: 25%;
  background-color: ${({ theme }) => theme.color.secondaryBG};
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
`
const BackSquare2 = styled(BackSquare)`
  bottom: 0;
  top: auto;
`
const RegistrationPage = () => (
  <Layout fluid={true}>
    <SEO
      title="Prihláška"
      description="Prihlás sa k nám, my ťa naučíme šoférovať."
    />
    <Section>
      <Container fluid={false}>
        <PageHeader header="Prihlás sa k nám" />
      </Container>
      <FormSection>
        <BackSquare />
        <Container fluid={false}>
          <RegistrationForm />
        </Container>
        <BackSquare2 />
      </FormSection>
      <TimelineSection>
        <Container fluid={false}>
          <Intro
            data-sal-duration="650"
            data-sal="fade"
            data-sal-easing="ease-in"
          >
            <span className="upTitle">Postup</span>
            <h1>A čo ďalej ? </h1>
          </Intro>
          <Timeline />
        </Container>
      </TimelineSection>
    </Section>
  </Layout>
)

export default RegistrationPage
