import { FastField } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"

export const CityField = (props: any) => {
  return (
    <FastField
      style={{
        width: "100%",
      }}
      component={TextField}
      id="address.city"
      name="address.city"
      type="text"
      label="Mesto"
    />
  )
}
