import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import * as Yup from "yup"
import { Link } from "gatsby"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { FormControl } from "@material-ui/core"

import Checkbox from "@material-ui/core/Checkbox"
import { FormHelperText } from "@material-ui/core"
import { useFormikContext } from "formik"

interface ListProps {
  className?: string
}
const List = (props: ListProps) => {
  return (
    <div className={props.className}>
      <span>- som plne spôsobilý na právne úkony</span>
      <span>- som zdravotne spôsobilý na vedenie motorových vozidiel</span>
      <span>
        - mi nie je uložená sankcia zákazu činnosti viesť motorové vozidlá v Slovenskej republike alebo v inom štáte,
        resp. uložená sankcia mi už bola v Slovenskej republike alebo v inom štáte zahľadená
      </span>
      <span>
        - nemám odňaté vodičské oprávnenie v Slovenskej republike alebo v inom štáte, resp. mi nebol zadržaný vodičský
        preukaz v Slovenskej republike alebo v inom štáte
      </span>
      <span>- som bol oboznámený s cenníkom autoškoly a s jeho všeobecnými podmienkami</span>
      <span>
        - Súhlasím, aby vyššie uvedené osobné údaje boli použité na vedenie dokumentácie o kurzoch podľa zákona č.
        93/2005 Z. z. o autoškolách a o zmene a doplnení niektorých zákonov
      </span>
    </div>
  )
}

const osobneUdaje =
  "Som si vedomý, že prevádzkovateľ Autoškola Hron s.r.o., bude spracúvať moje osobné údaje poskytnuté v tomto formulári. O spracúvaní mojich osobných údajov, ako aj o svojich právach, som bol poučený v dokumente: "

export interface AgreementSectionProps {
  className?: string
}

export interface Propsik {
  agreement: any
}

export const agreementSectionValidations = {
  agreement: Yup.object({
    accept: Yup.bool().oneOf([true], "Súhlas s vyhlásením je povinný na odoslanie prihlášky."),
    gdpr: Yup.bool().oneOf(
      [true],
      "Súhlas so spracovaním osobných údajov poskytnutých v tomto formulári je povinný na odoslanie prihlášky."
    ),
  }),
}

export const agreementSectionInitialValues = {
  agreement: {
    accept: false,
    gdpr: false,
  },
}

const AgreementSectionInner = (props: AgreementSectionProps & FormikProps<Propsik>) => {
  const { setFieldValue, errors } = useFormikContext()

  return (
    <section className={props.className}>
      <Grid container>
        <FormControl error={!!errors?.agreement?.accept}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="agreement.accept"
                onChange={(e) => setFieldValue("agreement.accept", e.target.checked)}
              />
            }
            id="accept"
            label={<CheckboxLabel>Súhlasím s nasledovným vyhlásením:</CheckboxLabel>}
          />
          <List className="accept-list" />
          <FormHelperText className="checkbox-helper-text" id="agreement.accept">
            {errors?.agreement?.accept}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid container>
        <FormControl error={!!errors?.agreement?.gdpr}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="agreement.gdpr"
                onChange={(e) => setFieldValue("agreement.gdpr", e.target.checked)}
              />
            }
            id="gdpr"
            label={
              <CheckboxLabel>
                {osobneUdaje}
                <Link to="/dokumenty">GDPR - Ochrana osobných údajov</Link>
              </CheckboxLabel>
            }
          />
          <FormHelperText className="checkbox-helper-text" id="agreement.gdpr">
            {errors?.agreement?.gdpr}
          </FormHelperText>
        </FormControl>
      </Grid>
    </section>
  )
}

export const AgreementSection = styled(AgreementSectionInner)`
  .accept-list {
    padding-left: 30px;
    span {
      font-size: 13px;
      text-align: left;
      display: block;
      margin-bottom: 8px;
    }
  }

  .checkbox-helper-text {
    padding-left: 30px;
  }
`

const CheckboxLabel = styled.span`
  text-align: left;
  font-size: 13px;
  display: block;
  margin: 10px 0;
  margin-bottom: 8px;
`
