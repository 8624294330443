import { FastField } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"

export const LastNameField = (props: any) => {
  return (
    <FastField
      style={{
        width: "100%",
      }}
      component={TextField}
      id="info.lastName"
      name="info.lastName"
      type="text"
      label="Priezvisko"
    />
  )
}
