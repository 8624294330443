import DateFnsUtils from "@date-io/date-fns"
import { FormControl, FormHelperText } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import skLocale from "date-fns/locale/sk"
import { FastField } from "formik"
import React from "react"

const FormikDatePicker = ({ name, form: { setFieldValue, errors }, field: { value = null }, ...rest }) => {
  return (
    <FormControl
      style={{
        width: "100%",
      }}
      error={!!errors?.info?.birthDate}
    >
      <KeyboardDatePicker
        label="Dátum narodenia"
        name="info.birthDate"
        format="dd.MM.yyyy"
        id="info.birthDate"
        style={{
          width: "100%",
        }}
        invalidDateMessage="Tento dátum nie je validný."
        maxDateMessage={null}
        cancelLabel="Zavrieť"
        okLabel="Pridať"
        maxDate={new Date().setDate(new Date().getDate() - 365 * 16)}
        value={value}
        error={!!errors?.info?.birthDate}
        onChange={(value) => {
          setFieldValue("info.birthDate", value)
        }}
      />
      {!!errors?.info?.birthDate && <FormHelperText>{errors?.info?.birthDate}</FormHelperText>}
    </FormControl>
  )
}

export const BirthDateSelectField = (props: any) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
      <FastField name="info.birthDate" component={FormikDatePicker} />
      <div style={{ color: "red", marginTop: ".5rem" }}></div>
    </MuiPickersUtilsProvider>
  )
}
