import { guardianSectionInitialValues } from "./../sections/GuardianSection/GuardianSection"
import { addressInfoInitialValues } from "../sections/AddressSection/utils/initialValues"
import { loginSectionInitialValues } from "../sections/LoginSection/LoginSection"
import { studentContactSectionInitialValues } from "../sections/StudentContactSection/StudentContactSection"
import { studentInfoInitialValues } from "./../sections/StudentInfo/utils/initialValues"
import { agreementSectionInitialValues } from "../sections/AgreementSection/AgreementSection"

export const registrationFormInitialValues = {
  ...studentInfoInitialValues,
  ...addressInfoInitialValues,
  ...studentContactSectionInitialValues,
  ...loginSectionInitialValues,
  ...guardianSectionInitialValues,
  ...agreementSectionInitialValues,
}
