import { Grid } from "@material-ui/core"
import { FormSectionHeading } from "../../../../components/shared/Registration/FormSectionHeading/FormSectionHeading"
import { FastField, Field } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"
import * as Yup from "yup"
import styled from "styled-components"

export interface LoginSectionProps {
  className?: string
}

export const loginSectionValidations = {
  password: Yup.string().required('Pole "Heslo" je povinné.').min(8, "Heslo musí mať minimálne 8 znakov"),
}

export const loginSectionInitialValues = {
  password: "",
}

const LoginSectionInner = (props: LoginSectionProps) => {
  return (
    <section className={props.className}>
      <FormSectionHeading>Prihlasovacie údaje</FormSectionHeading>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            disabled
            id="contact.email"
            name="contact.email"
            placeholder="jozko.mrkvicka@gmail.com"
            type="email"
            label="Email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            id="password"
            name="password"
            type="password"
            label="Heslo"
          />
        </Grid>
      </Grid>
    </section>
  )
}

export const LoginSection = styled(LoginSectionInner)``
