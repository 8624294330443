import React from "react"
import styled from "styled-components"

export interface FormHeaderProps {
  className?: string
}

const Description = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.color.secondaryAlfa};
  font-weight: bold;
`

const FormHeaderInner = (props: FormHeaderProps) => {
  return (
    <div
      className={props.className}
    >
      <h1>Prihláška</h1>
      <Description>
        Ak máš naozaj záujem prihlásiť sa v našej autoškole - autoškola HRON,
        vyplň a odošli nám tento prihlasovací formulár, my ťa následne budeme
        kontaktovať.
      </Description>
    </div>
  )
}

export const FormHeader = styled(FormHeaderInner)``
