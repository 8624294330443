import { FastField } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"

export const ZipField = (props: any) => {
  return (
    <FastField
      style={{
        width: "100%",
      }}
      component={TextField}
      id="address.zipCode"
      name="address.zipCode"
      type="number"
      label="PSČ"
      className="inpt-zip"
    />
  )
}
