import * as Yup from "yup"
export const studentSectionValidations = {
	info: Yup.object({
		firstName: Yup.string().required('Pole "Meno" je povinné.'),
		lastName: Yup.string().required('Pole "Priezvisko" je povinné.'),
		identificationNumber: Yup.string().required('Pole "Rodné číslo" je povinné.'),
		birthDate: Yup.string()
			.nullable()
			.required('Pole "Dátum narodenia" je povinné.')
			.test("age", "Si si istý, že už máš vek na vodičák?", (value) => {
				const birthDate = new Date(value)
				const today = new Date()
				const nYearsAgo = new Date(today.setDate(today.getDate() - 16 * 365))

				return nYearsAgo > birthDate
			}),
		birthPlace: Yup.string().required('Pole "Miesto narodenia" je povinné.'),
		idNumber: Yup.string().required('Pole "Číslo OP" je povinné.'),
	}),
}
