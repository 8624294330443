import { Grid } from "@material-ui/core"
import { FastField, useFormikContext } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"
import styled from "styled-components"
import * as Yup from "yup"
import { FormSectionHeading } from "../../../../components/shared/Registration/FormSectionHeading/FormSectionHeading"
import { genericRequiredMessage } from "../../../../lib/consts"

export interface GuardianSectionProps {
  className?: string
}

export const useIsYoungerThan = (age: number): boolean => {
  const { values } = useFormikContext()
  const birthDate = values?.info["birthDate"]
  const today = new Date()
  const diffTime = Math.abs(today - birthDate)
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))

  return diffDays < age
}

export const guardianSectionValidation = {
  guardian: Yup.object({}).when("info", {
    is: (value) => {
      const { birthDate } = value
      const dateOfBirth = new Date(birthDate)

      const seventeenYearsAgo = new Date(new Date().setDate(new Date().getDate() - 365 * 17))
      const eighteenYearsAgo = new Date(new Date().setDate(new Date().getDate() - 365 * 18))

      return eighteenYearsAgo < dateOfBirth
    },
    then: Yup.object({
      firstName: Yup.string().required('Pole "Meno" je povinné.'),
      lastName: Yup.string().required('Pole "Priezvisko" je povinné.'),
      identificationNumber: Yup.string().required('Pole "Rodné číslo" je povinné.'),
    }),
  }),
}

export const guardianSectionInitialValues = {
  guardian: {
    firstName: "",
    lastName: "",
    identificationNumber: "",
  },
}

const GuardianSectionInner = (props: GuardianSectionProps) => {
  const isUnderEighteen = useIsYoungerThan(18)
  return (
    <section className={props.className}>
      <FormSectionHeading disabled={!isUnderEighteen}>Zákonný zástupca</FormSectionHeading>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            id="guardian.firstName"
            name="guardian.firstName"
            type="guardian.firstName"
            label="Meno"
            disabled={!isUnderEighteen}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            id="guardian.lastName"
            name="guardian.lastName"
            type="guardian.lastName"
            label="Priezvisko"
            disabled={!isUnderEighteen}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FastField
            style={{
              width: "100%",
            }}
            component={TextField}
            id="guardian.identificationNumber"
            name="guardian.identificationNumber"
            type="guardian.identificationNumber"
            label="Rodné číslo"
            disabled={!isUnderEighteen}
          />
        </Grid>
      </Grid>
    </section>
  )
}

export const GuardianSection = styled(GuardianSectionInner)``
